import React from 'react'
import get from 'lodash/get'
import { RichText } from 'prismic-reactjs'
import styled from '@emotion/styled'

import { rhythm } from '../utils/typography'
import Layout from '../components/layout'
import NewsCard from '../components/ui/news-card'
import { linkResolver } from '../utils/link-resolver'
import htmlSerializer from '../utils/html-serializer'

const Content = styled.div`
  text-align: justify;
  margin-bottom: ${rhythm(2)};
  font-size: ${rhythm(0.7)};
`

function News({ data, urlPrefix, lang }) {
  const news = get(data, 'prismic.allNewss.edges')
  const {
    meta_title,
    meta_description,
    meta_keywords,
    page_subtitle,
    content_body,
    no_post_message,
  } = get(data, 'prismic.allNewspages.edges[0].node')
  return (
    <Layout
      title={RichText.asText(meta_title)}
      subtitle={RichText.asText(page_subtitle)}
      site={data.site}
      customKeywords={meta_keywords ? RichText.asText(meta_keywords) : null}
      customDescription={
        meta_description ? RichText.asText(meta_description) : null
      }
    >
      <Content>
        <RichText
            render={content_body}
            linkResolver={linkResolver}
            htmlSerializer={htmlSerializer}
        />
      </Content>
      {news.map(({ node }) => {
        return (
          <NewsCard
            node={node}
            urlPrefix={urlPrefix}
            key={node._meta.uid}
            lang={lang}
          />
        )
      })}
      {(!news || news.length === 0) && (
        <div style={{ textAlign: 'center' }}>
          {RichText.render(no_post_message)}
        </div>
      )}
    </Layout>
  )
}

export default News
