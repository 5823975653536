import React from 'react'
import { graphql } from 'gatsby'

import News from './_news'

export default props => <News {...props} urlPrefix={'/fr/news/'} lang={'fr'} />

export const pageQuery = graphql`
  query {
    site {
      ...SiteInformation
    }
    prismic {
      allNewss(lang: "fr-fr", sortBy: date_DESC) {
        edges {
          node {
            title
            image
            imageSharp {
              childImageSharp {
                fluid(maxHeight: 250) {
                  ...GatsbyImageSharpFluid
                  presentationWidth
                  presentationHeight
                }
              }
            }
            content
            date
            _meta {
              uid
            }
          }
        }
      }
      allNewspages(lang: "fr-fr") {
        edges {
          node {
            meta_title
            meta_keywords
            meta_description
            page_subtitle
            content_body
            no_post_message
            _meta {
              id
            }
          }
        }
      }
    }
  }
`
